<template>
  <div id="dasar" class="row container-fluid">
    <img src="../assets/header.jpeg" alt />
    <img src="../assets/gambarRumah.jpeg" alt />
    <div class="button-col">
      <div class="first-row">
        <a href="/addcostumer">
          <button type="button">DAFTAR NUP</button>
        </a>
      </div>
      <div class="second-row">
        <button type="button">PRODUK KAMI</button>
        <button type="button">KONTAK KAMI</button>
      </div>
      <div class="third-row">
        <button type="button">KANTOR PEMASARAN</button>
      </div>
    </div>

    <!-- <div class="row justify-content-md-center mt-5">
      <div class="row justify-content-md-center">
        <b-button id="daftarNup" style="margin-top: 20px" href="/addcostumer">DAFTAR NUP</b-button>
      </div>
      <div id="line2" class="row justify-content-md-center">
        <b-button class="justify-content-md-center" id="produkButton">PRODUK KAMI</b-button>
        <b-button id="kontakButton">KONTAK KAMI</b-button>
      </div>
      <div class="row justify-content-md-center mb-5">
        <b-button id="kantorPemasaran">KANTOR PEMASARAN</b-button>
      </div>
    </div>-->
    <img src="../assets/line3.jpeg" alt />
    <img src="../assets/product.jpeg" alt id="product" />
    <img src="../assets/peta.jpeg" alt />
    <img src="../assets/footer.jpeg" alt />
    <!-- <div class="row"></div> -->
  </div>
</template>
<script>
export default {
  methods: {
    mouseOver: function() {
      this.active = !this.active;
    }
  }
};
</script>
<style>
#product {
  max-width: 100;
}
#dasar {
  background-color: rgb(243, 243, 243);
}
.btn-secondary {
  border-radius: 20px;
  font-size: 18px;
  width: 50mm;
  background-color: darkblue;
  color: rgb(255, 255, 255);
}
#rumah2 {
  /* min-width: 100mm; */
  max-width: 150mm;
  border-radius: 80px;
}

#daftarNup {
  font-size: 25px;
  width: 65mm;
}
#produkButton {
  /* margin-top: 8mm; */
  margin-right: 50mm;
  /* margin-bottom: 10px; */
}

#line2 {
  margin-top: 20px;
  margin-bottom: 5px;
}

#kantorPemasaran {
  margin-top: 5px;
  width: 100mm;
}
p {
  align-self: center;
  padding-right: 100px;
  font-size: 18px;
  text-align: justify;
}
.row {
  --bs-gutter-x: 0rem;
}
/* Button */
.button-col {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
button {
  color: aliceblue;
  background-color: rgb(37, 27, 182);

  font-size: 25px;
  height: 50px;
  width: 300px;
  border-radius: 20px;
}

.second-row {
  margin: 20px;
  display: flex;
  justify-content: space-around;
}
.second-row button {
  font-size: 20px;
}

.third-row {
  display: flex;
  justify-content: space-around;
}
.third-row button {
  width: 500px;
  font-size: 21px;
}
/* Responsive */
@media screen and (max-width: 800px) {
  button {
    font-size: 15px;
    height: 40px;
    width: 150px;
  }
  .second-row {
    display: flex;
    margin: 5px;
    justify-content: space-around;
  }
  .second-row button {
    font-size: 15px;
  }

  .third-row {
    display: flex;
    justify-content: space-around;
  }
  .third-row button {
    width: 200px;
    font-size: 15px;
  }
}
</style>
